import { useState } from "react";
import authService, { AuthContext } from "services/authService";

function AuthProvider({ children }) {
  let [isAuth, setIsAuth] = useState(!!authService.getAccessToken());

  let signin = (token = authService.getFakeToken()) => {
    authService.setAccessToken(token);
    setIsAuth(true);
  };

  let signout = () => {
    authService.clearToken();
    setIsAuth(false);
  };

  let value = { isAuth, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
