import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

const Delete = ({ confirmDelete, closeDialog, loading }) => {
  return (
    <>
      <DialogTitle>Confirmer la suppression de l'évolution</DialogTitle>
      <DialogContent>
        Voulez-vous vraiment supprimer l'évolution
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeDialog}>
          Annuler
        </Button>
        <LoadingButton onClick={confirmDelete} loading={loading}>
          Oui
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default Delete;
