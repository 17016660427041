import { Db } from "./db";

const i18n = {
  [Db.benef_responsable]: "Responsable",
  [Db.benef_photo]: "Photo",
  [Db.benef_nom]: "Nom",
  [Db.benef_cin]: "CIN",
  [Db.benef_annee_naiss]: "Date de naissance",
  [Db.benef_lieu_naiss]: "Lieu de naissance",
  [Db.benef_genre]: "Genre",
  [Db.benef_matrimonial]: "Situation Matrimonial",
  [Db.benef_nbr_pers_active]: "Personne active",
  [Db.benef_nbr_pers_charge]: "Personne pris en charge",
  [Db.benef_email]: "Email",
  [Db.benef_telephone]: "Téléphone",
  [Db.benef_facebook]: "Facebook",
  [Db.benef_adress]: "Adresse",
  [Db.benef_fokotany]: "Fokotany",
  [Db.benef_commune]: "Commune",
  [Db.benef_district]: "District",
  [Db.benef_region]: "Région",
  [Db.benef_resp_assoc_groupement]: "Responsabilité Association Groupement",
  [Db.benef_education]: "Education",
  [Db.benef_annee_debut_activite_prof]: "Début activité professionelle",
  [Db.benef_nom_assoc]: "Association",
  [Db.benef_resp_assoc]: "Responsabilité association",
  [Db.benef_autre_responsabilite]: "Autre responsabilité",
  [Db.benef_autre_appartenance]: "Autre appartenance",
  [Db.benef_profession]: "Profession",
  [Db.benef_talent]: "Talent",
  [Db.evol_date_evolution]: "Date",
  [Db.evol_beneficiaire]: "evol_beneficiaire",
  [Db.evol_total_prod_vegetal]: "Total production Végétal",
  [Db.evol_total_prod_animal]: "Total production animal",
  [Db.evol_suffisance_alimentaire]: "Suffisance alimentaire",
  [Db.evol_suffisance_proteine]: "Suffisance Proteine",
  [Db.evol_stock_periode_soudure]: "Période soudure",
  [Db.evol_stock_alimentaire]: "Stock Alimentaire",
  [Db.evol_niveau_nutrition]: "Niveau nutrition",
  [Db.evol_total_vente_prod_vegetal]: "Total vente production végétal",
  [Db.evol_total_vente_prod_animal]: "Total vente production animal",
  [Db.evol_autre_revenu]: "Revenu",
  [Db.evol_suffisance_revenu]: "Suffisance revenu",
  [Db.evol_montant_epargne]: "Montant epargne",
  [Db.evol_depense_couverte_revenu]: "Dépense couverte par revenu",
  [Db.evol_investissment_envisage]: "Investissement envisagé",
  [Db.evol_investissement_effectue]: "Investissement effectué",
  [Db.evol_niveau_comprehension_ecologique]: "Niveau compréhension écologique",
  [Db.evol_sensibilite_environnement]: "Sensibilité environnementale",
  [Db.evol_pratique_norme_agro_eco]: "Pratique norme agro",
  [Db.evol_pratique_elevage_eco]: "Pratique élévage",
  [Db.evol_pratique_respect_norme_environnement]:
    "Pratique respect norme environnementale",
  [Db.evol_activite_impact_environnement_positif]:
    "Activité impactant environnement",
  [Db.evol_autre_infos]: "Autre",
};

export default i18n;
