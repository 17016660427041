import { Typography, Box, Button, useMediaQuery } from "@mui/material";
import { AuthContext } from "services/authService";
import { useContext } from "react";
import { useLocation, Navigate, useOutletContext } from "react-router-dom";

const LoginPage = () => {
  let auth = useContext(AuthContext);
  let { handleToggleMenu } = useOutletContext();

  let location = useLocation();

  const isLargeScreen = useMediaQuery("(min-width:940px)");

  if (auth.isAuth) {
    return (
      <Navigate to="/dashboard" state={{ path: location.pathname }} replace />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "flex-start",
        height: "100vh",
      }}
      pl={5}
    >
      <Typography variant="h2" component="h1" fontWeight="bold">
        Connexion
      </Typography>
      <Typography
        variant="h5"
        component="p"
        gutterBottom
        fontWeight="bold"
        sx={{ color: "rgba(0,0,0,0.3)" }}
      >
        Connectez-vous ici
      </Typography>

      {!isLargeScreen && (
        <Button
          color="primary"
          variant="contained"
          sx={{ mt: 5 }}
          size="small"
          onClick={handleToggleMenu}
        >
          Se connecter
        </Button>
      )}
    </Box>
  );
};

export default LoginPage;
