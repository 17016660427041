import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  RadialLinearScale,
  Legend,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import useFetch from "hooks/useFetch";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import authService from "services/authService";

import {
  Paper,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Db } from "helper/db";
import BuildGraph, {
  sTypeDougnhut,
  sTypePie,
  sTypeRadar,
  sTypeBar,
} from "./BuildGraph";

ChartJS.register(
  ArcElement,
  Tooltip,
  RadialLinearScale,
  Legend,
  PointElement,
  LineElement,
  Filler,
  CategoryScale,
  LinearScale,
  BarElement
);

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#6cad95" : "#308fe8",
  },
}));

function LinearProgressWithLabel({ label, value, ...props }) {
  return (
    <Box>
      <Typography variant="caption" color="text.secondary">
        {label}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <BorderLinearProgress
            variant="determinate"
            value={parseInt(value)}
            {...props}
          />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ textAlign: "right" }}
          >{`${Math.round(value)}`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

//Doc https://react-chartjs-2.js.org/examples/doughnut-chart

const aDataGraph = [
  { url: "stat_genre", type: sTypePie, label: "Genre" },
  { url: "stat_matrimonial", type: sTypePie, label: "Situation matrimonial" },
  { url: "stat_education", type: sTypePie, label: "Niveau éducation" },
  {
    url: "stat_suffisance_alim",
    type: sTypeDougnhut,
    label: "Suffisance alimentaire",
  },
  {
    url: "stat_suffisance_proteine",
    type: sTypeDougnhut,
    label: "Suffisance protéine",
  },
  {
    url: "stat_periode_soudure",
    type: sTypeDougnhut,
    label: "Suffisance stock période soudure",
  },
  {
    url: "stat_variation_stock_alim",
    type: sTypeDougnhut,
    label: "Variation stock alimentaire",
  },
  {
    url: "stat_nutritionnelle",
    type: sTypeDougnhut,
    label: "Niveau se situation nutritionnelle",
  },
  {
    url: "stat_suffisance_revenu",
    type: sTypeDougnhut,
    label: "Suffisance des revenus",
  },
  // {
  //   url: "stat_capacite_epargne",
  //   type: sTypeDougnhut,
  //   label: "Capacité épargne",
  // },
  {
    url: "stat_comprehension_pratique_agroeco",
    type: sTypeDougnhut,
    label: "Niveau de compréhension des bonnes pratiques agroécologiques",
  },
  {
    url: "stat_sensibilite_environnemental",
    type: sTypeDougnhut,
    label: "Sensibilité aux questions environnementales",
  },
  {
    url: "stat_pratique_agroeco",
    type: sTypeDougnhut,
    label: "Pratique de l'agroécologie",
  },
  {
    url: "stat_pratique_agroeco_elevage",
    type: sTypeDougnhut,
    label: "Pratique de l’agroécologie dans l'élevage",
  },
  {
    url: "stat_norme_environnement",
    type: sTypeDougnhut,
    label: "Pratique des normes environnementales",
  },
  {
    url: "stat_pratique_activite",
    type: sTypeDougnhut,
    label: "Pratiquant des activités à impact environnemental positif",
  },
  // {
  //   url: "stat_futur_investissement",
  //   type: sTypeBar,
  //   label: "Futurs investissements envisagés",
  // },
  // {
  //   url: "stat_productif_effectue",
  //   type: sTypeBar,
  //   label: "Investissement productifs effectués",
  // },
];

const Dashboard = () => {
  const [dataTotalBenef, loadTotal, cancelTotal] = useFetch("get_total_benef");
  const [dataStatAge, loadAge, cancelAge] = useFetch("stat_age");
  const [dataStatFuturInvest, loadFuturInvest, cancelFuturInvest] = useFetch(
    "stat_futur_investissement"
  );
  const [dataStatProdEffectue, loadProdEffectue, cancelProdEffectue] = useFetch(
    "stat_productif_effectue"
  );
  const [dataGraph, getGraph, cancelGraph] = useFetch("");
  const [readyToGet, setReadyToGet] = useState(false);

  const [graphData, setGraphData] = useState({});

  const [statTotal, setStatTotal] = useState(0);
  const [statAge, setStatAge] = useState({});
  const [statFuturInvest, setStatFuturInvest] = useState([]);
  const [statProdEffectue, setStatProdEffectue] = useState([]);
  const [expanded, setExpanded] = useState(0);

  const dataSite = {
    [Db.benef_region]: authService.getUserDetails()?.currentRegion?.value,
  }; // Just for test Remove

  useEffect(() => {
    loadTotal(dataSite, "post").then((rep) => {
      if (rep) {
        setStatTotal(rep);
      }
    });
  }, []);

  useEffect(() => {
    loadAge(dataSite, "post").then((rep) => {
      if (rep) {
        setStatAge(rep);
      }
    });
  }, []);

  useEffect(() => {
    loadFuturInvest(dataSite, "post").then((rep) => {
      if (rep) {
        setStatFuturInvest(rep);
      }
    });
  }, []);

  useEffect(() => {
    loadProdEffectue(dataSite, "post").then((rep) => {
      if (rep) {
        setStatProdEffectue(rep);
      }
    });
  }, []);

  const handleGraph = (url) => {
    getGraph(dataSite, "post", "json", false, false, url).then((resp) => {
      setGraphData((prev) => ({ ...prev, [url]: resp }));
    });
  };

  const handleExpanded = (item) => {
    setExpanded({
      ...expanded,
      [item]: expanded[item] ? !expanded[item] : true,
    });
    handleGraph(item);
  };

  return (
    <Box p={2}>
      <Typography variant="h3" component="h1" fontWeight="bold" mb="10px">
        Dashboard
      </Typography>

      {/*Total*/}
      <Box
        display="grid"
        gridTemplateColumns="repeat( auto-fill, minmax(150px, 250px) )"
        gap="10px"
        mb={2}
      >
        <Paper sx={{ backgroundColor: "#f2f8f8" }}>
          <Box p={1}>
            <Typography
              variant="body2"
              component="p"
              fontSize={34}
              textAlign="right"
              fontWeight="bold"
              color="#6cad95"
            >
              {statTotal}
            </Typography>
            <Typography variant="body1" component="p" fontWeight="bold">
              Total
            </Typography>
          </Box>
        </Paper>

        {/*Age*/}
        <Paper sx={{ backgroundColor: "#f2f8f8" }}>
          <Box p={1}>
            <Typography
              variant="body2"
              component="p"
              fontSize={34}
              textAlign="right"
              fontWeight="bold"
              color="#6cad95"
            >
              {statAge[Db.max_age]} ans
            </Typography>
            <Typography variant="body1" component="p" fontWeight="bold">
              Plus agé
            </Typography>
          </Box>
        </Paper>

        <Paper sx={{ backgroundColor: "#f2f8f8" }}>
          <Box p={1}>
            <Typography
              variant="body2"
              component="p"
              fontSize={34}
              textAlign="right"
              fontWeight="bold"
              color="#6cad95"
            >
              {statAge[Db.min_age]} ans
            </Typography>
            <Typography variant="body1" component="p" fontWeight="bold">
              Plus jeune
            </Typography>
          </Box>
        </Paper>

        <Paper sx={{ backgroundColor: "#f2f8f8" }}>
          <Box p={1}>
            <Typography
              variant="body2"
              component="p"
              fontSize={34}
              textAlign="right"
              fontWeight="bold"
              color="#6cad95"
            >
              {statAge[Db.total_jeune]}
            </Typography>
            <Typography variant="body1" component="p" fontWeight="bold">
              Jeunes (moins de 35ans)
            </Typography>
          </Box>
        </Paper>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat( auto-fit, minmax(350px, 1fr) )",
          gap: "15px",
        }}
        my={2}
      >
        <Box>
          {aDataGraph.map((item, key) => (
            <Accordion
              key={key}
              expanded={expanded[item.url] || false}
              onChange={() => handleExpanded(item.url)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{item.label}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {!!graphData[item.url] ? (
                  <BuildGraph
                    type={item.type}
                    // label={item.label}
                    data={graphData[item.url] || []}
                  />
                ) : (
                  <LinearProgress />
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>

        <Paper>
          <Box p={2}>
            <Typography variant="body1" component="p" fontWeight="bold">
              Futurs investissements envisagés
            </Typography>
            {statFuturInvest.map((item, key) => (
              <LinearProgressWithLabel
                key={key}
                label={item[Db.select_label]}
                value={item[Db.select_value]}
              />
            ))}
          </Box>
        </Paper>

        <Paper>
          <Box p={2}>
            <Typography variant="body1" component="p" fontWeight="bold">
              Investissement productifs effectués
            </Typography>
            {statProdEffectue.map((item, key) => (
              <LinearProgressWithLabel
                key={key}
                label={item[Db.select_label]}
                value={item[Db.select_value]}
              />
            ))}
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default Dashboard;
