import { Box, Typography, Button, Dialog } from "@mui/material";

import TableData from "components/TableData";
import { Db } from "../../helper/db";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Details from "./details";
import Delete from "./delete";

const Evolution = ({ currentId, close }) => {
  const [data, load, cancel] = useFetch("get_benef_evolution/" + currentId);
  const [dialOpen, setDialOpen] = useState(false);
  const [dialDelete, setDialDelete] = useState(false);
  const [currentIdEvo, setCurrentIdEvo] = useState(null);

  const navigate = useNavigate();

  const [dataDelete, loadDelete, cancelDelete] = useFetch(
    "evolution/" + currentIdEvo
  );

  const handleOpenDialog = () => {
    setDialOpen(!dialOpen);
  };
  const handleDialogDelete = () => {
    setDialDelete(!dialDelete);
  };

  const confirmDelete = async () => {
    loadDelete({}, "delete", "json", true).then((rep) => {
      if (rep) {
        load();
        handleDialogDelete();
      }
    });
  };

  const columns = [
    { field: Db.evol_id, hide: true },
    { field: Db.evol_date_evolution, headerName: "Date évolution", width: 130 },
    {
      field: Db.evol_total_prod_vegetal,
      headerName: "Total production végetal",
      width: 150,
    },
  ];

  const edit = async (data) => {
    await navigate("/set_evolution?evol=" + data.id);
  };

  const details = async (data) => {
    // await navigate("/details_evolution?ref=" + data.id);
    await setCurrentIdEvo(data.id);
    await handleOpenDialog();
  };

  const deleteEvol = async (data) => {
    await setCurrentIdEvo(data.id);
    await handleDialogDelete();
  };

  const addEvol = async () => {
    await navigate("/set_evolution?benef=" + currentId);
  };

  const actColums = [
    {
      field: "action",
      headerName: "",
      width: 250,
      renderCell: (params) => (
        <>
          <Button color="primary" size="small" onClick={() => edit(params)}>
            Modifier
          </Button>
          <Button color="primary" size="small" onClick={() => details(params)}>
            Details
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={() => deleteEvol(params)}
          >
            Supprimer
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    load();

    return () => {
      cancel.cancel();
      cancelDelete.cancel();
    };
  }, []);

  return (
    <div>
      <Box px={2}>
        <Box mb={2}>
          <IconButton aria-label="close" onClick={close}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="h5" component="h1" fontWeight="bold">
          Evolution
          <Button color="primary" size="small" onClick={() => addEvol()}>
            Ajouter
          </Button>
        </Typography>
      </Box>

      <Box p={2}>
        <TableData
          rows={data.data || []}
          columns={[...columns, ...actColums]}
          height="82vh"
          width="100%"
          loading={data.loading}
        />
      </Box>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={dialOpen}
        onClose={handleOpenDialog}
      >
        {dialOpen && (
          <Details currentId={currentIdEvo} close={handleOpenDialog} />
        )}
      </Dialog>

      <Dialog fullWidth={true} maxWidth={"sm"} open={dialDelete}>
        {dialDelete && (
          <Delete
            confirmDelete={confirmDelete}
            closeDialog={handleDialogDelete}
            loading={dataDelete.loading}
          />
        )}
      </Dialog>
    </div>
  );
};

export default Evolution;
