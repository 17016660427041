import { TEXT, SELECT, FILE, TAG, ADDSELECT } from "components/FormFieldData";
import { Db } from "helper/db";

const fields = [
  // Relatif à la securité alimentaire
  {
    formType: TEXT,
    label: "Total surface disponible ",
    name: Db.evol_surface_dispo,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Total surface utilisée (cultivée)",
    name: Db.evol_surface_utilise,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Total de production végétale (kg) ",
    name: Db.evol_total_prod_vegetal,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Total de production animale (kg) ",
    name: Db.evol_total_prod_animal,
    type: "text",
  },
  {
    formType: SELECT,
    label: "Suffisance alimentaire",
    name: Db.evol_suffisance_alimentaire,
    options: [],
  },
  {
    formType: SELECT,
    label: "Suffisance en protéine",
    name: Db.evol_suffisance_proteine,
    options: [],
  },
  {
    formType: SELECT,
    label: "Stock en periode de soudure",
    name: Db.evol_stock_periode_soudure,
    options: [],
  },
  {
    formType: SELECT,
    label: "Stock alimentaire",
    name: Db.evol_stock_alimentaire,
    options: [],
  },
  {
    formType: SELECT,
    label: "Niveau de nutrition",
    name: Db.evol_niveau_nutrition,
    options: [],
  },

  // Relatif aux revenus
  {
    formType: TEXT,
    label: "Total de vente de la production végétale (kg) ",
    name: Db.evol_total_vente_prod_vegetal,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Total de vente de la production animale (kg) ",
    name: Db.evol_total_vente_prod_animal,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Autre revenus (MGA)",
    name: Db.evol_autre_revenu,
    type: "number",
  },
  {
    formType: SELECT,
    label: "Suffisance des revenus",
    name: Db.evol_suffisance_revenu,
    options: [],
  },
  {
    formType: SELECT,
    label: "Montant épargné",
    name: Db.evol_montant_epargne,
    options: [],
  },
  {
    formType: TEXT,
    label: "Dépenses couvertes par les revenus",
    name: Db.evol_depense_couverte_revenu,
    type: "text",
  },
  {
    formType: ADDSELECT,
    label: "Futurs investissements envisagés",
    name: Db.evol_investissment_envisage,
    options: [],
  },
  {
    formType: ADDSELECT,
    label: "Investissement productifs effectués",
    name: Db.evol_investissement_effectue,
    options: [],
  },

  // Relatif à l'environnement
  {
    formType: SELECT,
    label: "Niveau de compréhension des bonnes pratiques écologiques",
    name: Db.evol_niveau_comprehension_ecologique,
    options: [],
  },
  {
    formType: SELECT,
    label: "Sensibilité aux questions environnementales",
    name: Db.evol_sensibilite_environnement,
    options: [],
  },
  {
    formType: SELECT,
    label: "Pratique des normes agro-écologiques",
    name: Db.evol_pratique_norme_agro_eco,
    options: [],
  },
  {
    formType: SELECT,
    label: "Pratique de l’élevage écologique",
    name: Db.evol_pratique_elevage_eco,
    options: [],
  },

  {
    formType: SELECT,
    label: "Pratique et respect des normes environnementales",
    name: Db.evol_pratique_respect_norme_environnement,
    options: [],
  },
  {
    formType: SELECT,
    label: "Activités à impact environnemental positif",
    name: Db.evol_activite_impact_environnement_positif,
    options: [],
  },
  {
    formType: TEXT,
    label: "Autre infos",
    name: Db.evol_autre_infos,
    type: "text",
  },
];

export default fields;
