import axios from "axios";
import authService from "services/authService";

export const DEV_MAIN_URL = "http://192.168.88.57:41/api";
// export const DEV_MAIN_URL = "https://api-benef.fm3a.org/api";
export const PROD_MAIN_URL = "https://api-benef.fm3a.org/api";
export const MAIN_URL =
  process.env.NODE_ENV === "development" ? DEV_MAIN_URL : PROD_MAIN_URL;
const API = axios.create({
  baseURL: MAIN_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  (config) => {
    const token = authService.getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete API.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

export { API };
