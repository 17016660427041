import {
  Box,
  Typography,
  Button,
  Dialog,
  Drawer,
  TextField,
} from "@mui/material";

import TableData from "components/TableData";
import { Db } from "../../helper/db";
import useFetch from "hooks/useFetch";
import Pagination from "@mui/material/Pagination";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

import Details from "./details";
import Delete from "./delete";
import Evolution from "pages/Evolution";
import LoadingButton from "@mui/lab/LoadingButton";
import fileDownload from "js-file-download";

const Beneficiaire = () => {
  const [data, load, cancel] = useFetch("get_beneficiaire");
  const [dataAssoc, loadAssoc, cancelAssoc] = useFetch("get_association");
  const [dialOpen, setDialOpen] = useState(false);
  const [dialDelete, setDialDelete] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [listAssoc, setAssoc] = useState({});
  const navigate = useNavigate();

  const initFiltre = {
    [Db.benef_nom_assoc]: [],
    [Db.benef_fokotany]: "",
    [Db.benef_commune]: "",
  };
  const [filtreData, setFiltreData] = useState(initFiltre);
  const [selected, setSelected] = useState([]);
  const [totalPage, setTotalPage] = useState(1);

  const [dataDelete, loadDelete, cancelDelete] = useFetch(
    "beneficiaire/" + currentId
  );

  const [exportXls, loadExportXls, cancelExpoXls] = useFetch("export_xls");

  const [page, setPage] = useState({ page: 1, last_page: 0 });
  const [lastPage, setLastpage] = useState(1);

  const clearFiltre = () => {
    setFiltreData(initFiltre);
  };

  const handleOpenDialog = () => {
    setDialOpen(!dialOpen);
  };
  const handleDialogDelete = () => {
    setDialDelete(!dialDelete);
  };
  const handleOpenDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handlePage = (ev, value) => {
    setPage({ ...page, page: value });
  };

  const edit = async (data) => {
    await navigate(`/ajout_beneficiaire?ref=${data.id}`);
  };

  const details = async (data) => {
    await setCurrentId(data.id);
    await handleOpenDialog();
  };

  const deleteBeneficiaire = async (data) => {
    await setCurrentId(data.id);
    await handleDialogDelete();
  };

  const evolution = async (data) => {
    await setCurrentId(data.id);
    await handleOpenDrawer();
  };

  const confirmDelete = async () => {
    loadDelete({}, "delete", "json", true).then((rep) => {
      if (rep) {
        load();
        handleDialogDelete();
      }
    });
  };

  const handleExportXls = () => {
    loadExportXls({ ...page, ...filtreData }, "post", "blob");
  };

  const handleLoad = () => {
    let aAssocSelected = selected.map((item) => item[Db.select_value]);
    load(
      { ...page, ...filtreData, [Db.benef_nom_assoc]: aAssocSelected },
      "post"
    );
  };

  const handleFilter = (item) => {
    setFiltreData({ ...filtreData, [item.target.name]: item.target.value });
  };

  const handleFilterSelect = (item) => {
    setSelected(item);
  };

  const columns = [
    { field: Db.benef_id, hide: true },
    { field: Db.benef_nom, headerName: "Nom", width: 350 },
    { field: Db.benef_nom_assoc, headerName: "Association", width: 150 },
    { field: Db.benef_fokotany, headerName: "Fokotany", width: 150 },
    { field: Db.benef_commune, headerName: "Commune", width: 150 },
  ];

  const actColums = [
    {
      field: "action",
      headerName: "",
      width: 350,
      renderCell: (params) => (
        <>
          <Button color="primary" size="small" onClick={() => details(params)}>
            Details
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={() => evolution(params)}
          >
            Evolution
          </Button>
          <Button color="primary" size="small" onClick={() => edit(params)}>
            Modifier
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={() => deleteBeneficiaire(params)}
          >
            Supprimer
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    load({ ...page, ...filtreData }, "post").then((resp) => {
      if (resp) {
        setTotalPage(resp.last_page);
      }
    });

    return () => {
      cancel.cancel();
      cancelDelete.cancel();
    };
  }, [page]);

  useEffect(() => {
    loadAssoc().then((rep) => {
      if (rep) {
        setAssoc(rep);
      }
    });
  }, []);

  useEffect(() => {
    if (exportXls.success) {
      fileDownload(exportXls.data, "beneficiaire.xlsx");
    }
  }, [exportXls.success]);

  return (
    <div>
      <Box p={2}>
        <Typography variant="h5" component="h1" fontWeight="bold">
          Bénéficiaire
        </Typography>
      </Box>

      <Box px={2} sx={{ display: "flex", gap: "5px", flexDirection: "column" }}>
        <Box sx={{ display: "flex", gap: "5px" }}>
          <TextField
            variant="outlined"
            size="small"
            name={Db.benef_commune}
            onChange={handleFilter}
            label="Commune"
          />

          <TextField
            variant="outlined"
            size="small"
            name={Db.benef_fokotany}
            onChange={handleFilter}
            label="Fokotany"
          />
        </Box>

        <Select
          name={Db.benef_nom_assoc}
          placeholder={"Filtre Association"}
          options={listAssoc}
          onChange={handleFilterSelect}
          isMulti
        />

        <Box sx={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}>
          <Button
            onClick={handleLoad}
            color="primary"
            variant="outlined"
            size="small"
          >
            Filtrer
          </Button>

          <Button
            onClick={handleExportXls}
            color="primary"
            variant="outlined"
            size="small"
          >
            Exporter
          </Button>
        </Box>
      </Box>

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={dialOpen}
        onClose={handleOpenDialog}
      >
        {dialOpen && <Details currentId={currentId} close={handleOpenDialog} />}
      </Dialog>

      <Dialog fullWidth={true} maxWidth={"sm"} open={dialDelete}>
        {dialDelete && (
          <Delete
            confirmDelete={confirmDelete}
            closeDialog={handleDialogDelete}
            loading={dataDelete.loading}
          />
        )}
      </Dialog>

      <Box p={2}>
        <TableData
          rows={data?.data?.data || []}
          columns={[...columns, ...actColums]}
          height="60vh"
          width="100%"
          loading={data.loading}
        />
      </Box>

      <Pagination
        count={totalPage}
        onChange={handlePage}
        color="primary"
        size="small"
      />

      <Drawer anchor={"right"} open={drawerOpen} onClose={handleOpenDrawer}>
        <Box width={600}></Box>
        {drawerOpen && (
          <Evolution currentId={currentId} close={handleOpenDrawer} />
        )}
      </Drawer>
    </div>
  );
};

export default Beneficiaire;
