import { useState, useEffect } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import authService from "services/authService";
import useFetch from "hooks/useFetch";

import { Db } from "helper/db";
import "./style.scss";

const Profil = () => {
  const [error, setError] = useState(false);
  const [details, setDetails] = useState({
    [Db.user_login]: "",
    [Db.user_mail]: "",
  });
  let _temp = authService.getUserDetails();
  const [profilData, loadProfil, cancelProfil] = useFetch(
    `/update_profil`
  );

  useEffect(() => {
    setDetails({
      ...details,
      [Db.user_login]: _temp[Db.user_login],
      [Db.user_mail]: _temp[Db.user_mail],
    });

    return () => cancelProfil.cancel();
  }, []);

  const handleChanges = (item) => {
    setDetails({ ...details, [item.target.name]: item.target.value });
  };

  const makeSave = () => {
    setError(false);

    if (details.hasOwnProperty(Db.user_mdp)) {
      if (details[Db.user_mdp] !== details[Db.user_mdp_confirm]) {
        setError(true);
        return false;
      }
    }
    loadProfil(details, "post", "json", true).then((resp) => {
      if (resp) {
        authService.setUserDetails({
          ..._temp,
          [Db.user_login]: details[Db.user_login],
          [Db.user_mail]: details[Db.user_mail],
        });
      }
    });
  };

  return (
    <div className="benef_profil">
      <div className="benef_profil-header">
        <div>
          <LoadingButton
            variant="outlined"
            color="primary"
            size="small"
            loading={profilData.loading}
            onClick={makeSave}
          >
            Sauvegarder
          </LoadingButton>
        </div>
      </div>

      <div className="benef_profil-title">
        <p className="block-description">Utilisateur</p>
        <h3 className="block-title">Modifier profile utilisateur</h3>
      </div>

      <div className="benef_profil-body">
        <TextField
          label="login"
          variant="filled"
          size="small"
          fullWidth
          className="block-field"
          name={Db.user_login}
          value={details[Db.user_login]}
          onChange={handleChanges}
        />
        <TextField
          label="Email"
          variant="filled"
          size="small"
          fullWidth
          className="block-field"
          name={Db.user_mail}
          value={details[Db.user_mail]}
          onChange={handleChanges}
        />
        <br />
        <br />
        <br />
        <br />
        <TextField
          label="Mot de passe"
          variant="filled"
          size="small"
          fullWidth
          className="block-field"
          name={Db.user_mdp}
          value={details[Db.user_mdp] || ""}
          onChange={handleChanges}
          type="password"
        />
        <TextField
          label="Confirmer mot de passe"
          variant="filled"
          size="small"
          fullWidth
          className="block-field"
          name={Db.user_mdp_confirm}
          value={details[Db.user_mdp_confirm] || ""}
          onChange={handleChanges}
          type="password"
        />
      </div>
    </div>
  );
};

export default Profil;
