import { Box, Typography, Collapse, CircularProgress } from "@mui/material";

const Chargement = ({ loading }) => {
  return (
    <Collapse
      in={loading}
      timeout={{ enter: 1000, exit: 2000 }}
      easing={{ enter: "1s", exit: "1s" }}
    >
      <Box p={2} pb={0} display="flex" alignItems="center" gap="15px">
        <Typography variant="subtitle2" component="span">
          Chargement des données
        </Typography>{" "}
        <CircularProgress size="15px" />
      </Box>
    </Collapse>
  );
};

export default Chargement;
