import { Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm, useWatch } from "react-hook-form";
import FormFieldData from "components/FormFieldData";

import fields from "./fields";
import { useEffect, useState } from "react";
import authService from "services/authService";
import useFetch from "hooks/useFetch";
import { Db } from "helper/db";

import useQuery from "hooks/useQuery";
import { toFormdata } from "helper/utils";
import Chargement from "components/Chargement";

import "./style.scss";
import { MAIN_URL } from "services/api";

const Add = () => {
  let queryId = useQuery().get("ref");

  const { control, register, handleSubmit, setValue } = useForm();
  let imageWath = useWatch({ control, name: Db.benef_photo });

  const [srcImage, setSrcImage] = useState("");
  const [isDisable, setIdDisable] = useState(false);

  const allData = authService.getUserDetails();

  const aInitForm = {
    [Db.benef_profession]: [],
    [Db.benef_talent]: [],
    [Db.benef_autre_appartenance]: [],
    [Db.benef_nom_assoc]: [],
    [Db.benef_genre]: [],
    [Db.benef_matrimonial]: [],
    [Db.benef_region]: [],
    [Db.benef_district]: [],
  };

  const [allOptions, setAllOptions] = useState({
    [Db.benef_profession]: [],
    [Db.benef_talent]: [],
    [Db.benef_autre_appartenance]: [],
    [Db.benef_nom_assoc]: [],
    [Db.benef_education]: allData[Db.key_education] || [],
    [Db.benef_genre]: allData[Db.key_genre] || [],
    [Db.benef_matrimonial]: allData[Db.key_matrimonial] || [],
    [Db.benef_region]: allData[Db.key_region] || [],
    [Db.benef_district]: allData[Db.key_district] || [],
  });

  const [selectedVal, setSelectedVal] = useState({});

  const [dataAssoc, loadAssoc, clsAssoc] = useFetch("get_association");
  const [dataAppartenance, loadAppartenance, clsAppartenance] =
    useFetch("get_appartenance");
  const [dataTalent, loadTalent, clsTalent] = useFetch("get_talent");
  const [dataProfession, loadProfession, clsProfession] =
    useFetch("get_profession");

  const [data, loadBef, cls] = useFetch("get_beneficiaire/");
  const [dataSet, loadSetBef, clsSet] = useFetch("beneficiaire");

  const setSelectValue = (name, value) => {
    setSelectedVal((prev) => ({ ...prev, [name]: value }));
  };

  const [loadImage, setLoadImage] = useState(false);

  useEffect(() => {
    (async function () {
      if (!!queryId && queryId !== null) {
        loadBef({}, "get", "json", false, false, queryId).then((resp) => {
          if (resp) {
            let { id, autre_appart, ...other } = resp;

            if (!!other[Db.benef_photo] && other[Db.benef_photo].length > 0) {
              setSrcImage(`${MAIN_URL}/get_image/${other[Db.benef_photo]}`);
            }

            setLoadImage(true);

            for (let item in other) {
              setValue(item, other[item]);

              if (Object.keys(allOptions).includes(item)) {
                setSelectValue(item, other[item]);
              }
            }
          }
        });
      }
      await loadAssoc().then((rep) => {
        if (rep) {
          setAllOptions((prev) => ({ ...prev, [Db.benef_nom_assoc]: rep }));
        }
      });

      await loadAppartenance().then((rep) => {
        if (rep) {
          setAllOptions((prev) => ({
            ...prev,
            [Db.benef_autre_appartenance]: rep,
          }));
        }
      });

      await loadTalent().then((rep) => {
        if (rep) {
          setAllOptions((prev) => ({ ...prev, [Db.benef_talent]: rep }));
        }
      });

      await loadProfession().then((rep) => {
        if (rep) {
          setAllOptions((prev) => ({ ...prev, [Db.benef_profession]: rep }));
        }
      });
    })();

    return () => {
      clsAssoc.cancel();
      clsAppartenance.cancel();
      clsTalent.cancel();
      clsProfession.cancel();
      cls.cancel();
    };
  }, []);

  useEffect(() => {
    if (!imageWath || imageWath == null || imageWath[0] == undefined) return;
    console.log(
      "🚀 ~ file: index.jsx ~ line 123 ~ useEffect ~ imageWath",
      imageWath
    );

    if (typeof imageWath === "object") {
      setSrcImage(URL.createObjectURL(imageWath[0]));
    }
    setLoadImage(false);
  }, [imageWath]);

  const sendData = async (dataSend) => {
    let _tmp = { ...aInitForm, ...dataSend, ...selectedVal };

    let formdata = await toFormdata(_tmp);

    if (
      dataSend[Db.benef_photo] !== null &&
      typeof dataSend[Db.benef_photo] === "object"
    ) {
      formdata.delete(Db.benef_photo);
      formdata.append(Db.benef_photo, dataSend[Db.benef_photo][0]);
    }

    let _param = !!queryId && queryId !== null ? `/${queryId}?_method=PUT` : "";
    loadSetBef(formdata, "post", "json", true, true, _param).then((rep) => {
      if (rep) {
        setIdDisable(true);
        // setDataList(initList);
        // setTimeout(closeDrawer(), 500);
      }
    });
  };

  return (
    <div className="add_benef">
      <Box p={2} pb={0}>
        <Typography variant="h5" component="h1" fontWeight="bold">
          Ajout ou modification de bénéficiaire
        </Typography>
        <Typography variant="subtitle2" component="p">
          Ajouter ou modifier ci-dessous un bénéficiaire
        </Typography>
      </Box>

      <Chargement loading={data.loading} />

      <Box p={2}>
        <div className="image_container">
          <img src={srcImage} alt="" />
        </div>
      </Box>

      <form>
        <Box
          p={2}
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat( auto-fill, minmax(280px, 1fr) )",
            gap: "15px",
            overflow: "hidden",
          }}
        >
          {fields.map((props, key) => (
            <FormFieldData
              key={key}
              {...props}
              {...register(props.name)}
              control={control}
              options={allOptions[props.name]}
              selectValue={selectedVal[props.name]}
              selectFunction={setSelectValue}
            />
          ))}
        </Box>
        <Box p={2}>
          <LoadingButton
            onClick={handleSubmit(sendData)}
            color="primary"
            variant="contained"
            disabled={isDisable}
            loading={dataSet.loading}
          >
            Envoyer
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
};

export default Add;
