import { forwardRef } from "react";
import { TextField, Typography, Box } from "@mui/material";
import Select from "react-select";

import CreatableSelect from "react-select/creatable";

export const TEXT = "TEXT";
export const SELECT = "SELECT";
export const ADDSELECT = "ADDSELECT";
export const FILE = "FILE";
export const TAG = "TAG";

const boxAttr = {
  display: "flex",
  flexDirection: "column",
};

const FormFieldData = forwardRef(
  (
    {
      formType,
      options = [],
      label,
      control,
      selectFunction,
      selectValue,
      isMulti,
      ...props
    },
    ref
  ) => {
    const handleChange = (value) => {
      selectFunction(props.name, value);
    };

    switch (formType) {
      case TEXT:
        return (
          <Box {...boxAttr}>
            <Typography variant="subtitle2" component="span" fontWeight="bold">
              {label}
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              {...props}
              inputRef={ref}
            />
          </Box>
        );

      case FILE:
        return (
          <Box {...boxAttr}>
            <Typography variant="subtitle2" component="span" fontWeight="bold">
              {label}
            </Typography>
            <TextField
              type="file"
              variant="outlined"
              size="small"
              accept="image/*"
              {...props}
              inputRef={ref}
            />
          </Box>
        );
      case SELECT:
        return (
          <Box {...boxAttr}>
            <Typography variant="subtitle2" component="span" fontWeight="bold">
              {label}
            </Typography>
            <Select
              options={options}
              placeholder="Choisir..."
              {...props}
              menuPortalTarget={document.body}
              className="CtSelect"
              classNamePrefix="CTreactSelect"
              value={selectValue || []}
              onChange={handleChange}
              // onChange={onChange}
            />
          </Box>
        );
      case ADDSELECT:
        return (
          <Box {...boxAttr}>
            <Typography variant="subtitle2" component="span" fontWeight="bold">
              {label}
            </Typography>
            <CreatableSelect
              isMulti={isMulti == undefined ? true : isMulti}
              options={options}
              placeholder="Choisir..."
              {...props}
              menuPortalTarget={document.body}
              className="CtSelect"
              classNamePrefix="CTreactSelect"
              onChange={handleChange}
              value={selectValue || []}
            />
          </Box>
        );
      default:
        return (
          <Typography variant="p" component="p">
            Veuillez mettre un type
          </Typography>
        );
    }
  }
);

export default FormFieldData;
