import { createTheme } from "@mui/material";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#009633",
    },
    secondary: {
      main: "#ff3d00",
    },
    darksecondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: 13,
  },
});

const theme = createTheme(defaultTheme, {
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          overflow: "hidden",
          borderRadius: 4,
          backgroundColor: "rgb(0,0,0,0.015)",
          transition: defaultTheme.transitions.create([
            "border-color",
            "box-shadow",
          ]),
          /* "&:hover": {
            // backgroundColor: "rgb(0,0,0,0.05)",
          }, */
        },
      },
    },
    /*  MuiFormHelperText: {
      root: {
        fontSize: "0.65rem",
      },
    }, */
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&:before": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled):before": {
            borderBottom: "1px solid rgba(0, 0, 0, 0.3)",
          },
        },
        // inputMarginDense: {
        //   paddingTop: "18px",
        // },
      },
    },
    // MuiInputLabel: {
    //   styleOverrides: {
    //     root: {
    //       fontWeight: "bold",
    //     },
    //     filled: {
    //       "&.MuiInputLabel-marginDense": {
    //         transform: "translate(12px, 14px) scale(1) ",
    //       },
    //     },
    //   },
    // },
    /*
    MuiAutocomplete: {
      inputRoot: {
        "&[class*='MuiFilledInput-root']": {
          paddingTop: "18px",
          paddingLeft: "8px",
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: "0.8rem",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "0.9rem",
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#c8cdc9",
      },
    },
    MuiAppBar: {
      root: {
        backgroundColor: "#fafafa",
        borderBottom: "rgba(0, 0, 0, 0.2)",
        zIndex: defaultTheme.zIndex.drawer + 1,
      },
      positionFixed: {
        zIndex: defaultTheme.zIndex.drawer + 1,
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        padding: "2px 14px",
      },
    },
    MuiFormControlLabel: {
      root: {
        height: 30,
      },
      label: {
        fontSize: "0.9rem",
      },
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 5,
      },
      bar: {
        borderRadius: 5,
      },
    },
    MuiChip: {
      sizeSmall: {
        height: 18,
      },
    },
    MuiAlert: {
      root: {
        padding: "0 10px",
      },
    }, */
    /* MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: "none",
      },
    }, */
  },
});

export default theme;
export { defaultTheme };
