import { Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import FormData from "components/FormFieldData";

import fields from "./fields";
import { useEffect, useState } from "react";
import useFetch from "hooks/useFetch";
import { Db } from "helper/db";
import {useLocation} from "react-router";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Add = () => {
  const { control, register, handleSubmit, setValue } = useForm();
  let query = useQuery();
  let userId;

  if (query.get("ref")) {
    userId = query.get("ref");
  }

  const [data, loadUser, cls] = useFetch("get_user/");
  const [dataEvol, loadSetUser, clsUser] = useFetch("user");

  useEffect(() => {
    (async function () {
      if (!!userId && userId !== null) {
        loadUser({}, "get", "json", false, false, userId).then(
          (resp) => {
            if (resp) {
              let { id, ...other } = resp;

              for (let item in other) {
                setValue(item, other[item], { shouldTouch: true });
              }
            }
          }
        );
      }
    })();

    return () => {
      cls.cancel();
    };
  }, []);

  const sendData = async (dataSend) => {
    console.log(
      "🚀 ~ file: index.jsx ~ line 18 ~ sendData ~ dataSend",
      dataSend
    );

    let _param = !!userId && userId !== null ? `/${userId}?_method=PUT`  : "";
    loadSetUser(dataSend, "post", "json", true, true, _param).then((rep) => {
      if (rep) {
        // setDataList(initList);
        // setTimeout(closeDrawer(), 500);
      }
    });
  };

  return (
    <div>
      <Box p={2} pb={0}>
        <Typography variant="h5" component="h1" fontWeight="bold">
          Ajout ou modification utilisateur
        </Typography>
      </Box>

      <form>
        <Box
          p={2}
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat( auto-fill, minmax(280px, 1fr) )",
            gap: "15px",
            overflow: "hidden",
          }}
        >
          {fields.map((props, key) => (
            <FormData
              key={key}
              {...props}
              {...register(props.name)}
              control={control}
            />
          ))}
        </Box>
        <Box p={2}>
          <LoadingButton
            onClick={handleSubmit(sendData)}
            color="primary"
            variant="contained"
          >
            Envoyer
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
};

export default Add;
