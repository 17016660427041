import { createContext } from "react";
import { Db } from "helper/db";

export const Token = "SUIVI_EVAL_TOKEN";
const FAKE_TOKEN =
  "eyFAKETOKEN1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c";
const USER_STORE = "SUIVI_EVAL_USER_STORE";

export const AuthContext = createContext();

const authService = {
  getAccessToken: () => {
    return localStorage.getItem(Token);
  },

  clearToken: () => {
    // localStorage.removeItem(`persist:${SIEL_KEY}`);
    localStorage.removeItem(Token);
    localStorage.removeItem(USER_STORE);

    sessionStorage.removeItem(Db.logged_login);
    sessionStorage.removeItem(Db.logged_nom);
    sessionStorage.removeItem(Db.logged_role);
  },

  setAccessToken: (token) => {
    authService.isLogged = true;
    localStorage.setItem(Token, token);
  },

  setUserDetails: (item) => {
    localStorage.setItem(USER_STORE, JSON.stringify(item));
  },

  getUserDetails: () => {
    return JSON.parse(localStorage.getItem(USER_STORE));
  },

  updateUserDetails: (key, value) => {
    let _temp = JSON.parse(localStorage.getItem(USER_STORE));
    _temp[key] = value;
    localStorage.setItem(USER_STORE, JSON.stringify(_temp));
  },

  getFakeToken: () => FAKE_TOKEN,
};

export default authService;
