import { Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useForm } from "react-hook-form";
import FormData from "components/FormFieldData";

import fields from "./fields";
import { useEffect, useState } from "react";
import authService from "services/authService";
import useFetch from "hooks/useFetch";
import { Db } from "helper/db";
import {useLocation} from "react-router";
import {toFormdata} from "../../../helper/utils";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Add = () => {
  const { control, register, handleSubmit, setValue } = useForm();
  let query = useQuery();
  let evolId, benefId;

  if (query.get("evol")) {
    evolId = query.get("evol");
  }
  if (query.get("benef")) {
    benefId = query.get("benef");
  }


  const allData = authService.getUserDetails();

  const aInitForm = {
    [Db.evol_suffisance_alimentaire]: [],
    [Db.evol_suffisance_proteine]: [],
    [Db.evol_stock_periode_soudure]: [],
    [Db.evol_stock_alimentaire]: [],
    [Db.evol_niveau_nutrition]: [],
    [Db.evol_suffisance_revenu]: [],
    [Db.evol_montant_epargne]: [],
    [Db.evol_investissment_envisage]: [],
    [Db.evol_investissement_effectue]: [],
    [Db.evol_niveau_comprehension_ecologique]: [],
    [Db.evol_sensibilite_environnement]: [],
    [Db.evol_pratique_norme_agro_eco]: [],
    [Db.evol_pratique_elevage_eco]: [],
    [Db.evol_pratique_respect_norme_environnement]: [],
    [Db.evol_activite_impact_environnement_positif]: [],
  };

  const [allOptions, setAllOptions] = useState({
    [Db.evol_suffisance_alimentaire]: allData[Db.key_suffisance] || [],
    [Db.evol_suffisance_proteine]: allData[Db.key_suffisance] || [],
    [Db.evol_stock_periode_soudure]: allData[Db.key_suffisance] || [],
    [Db.evol_stock_alimentaire]: allData[Db.key_alim] || [],
    [Db.evol_niveau_nutrition]: allData[Db.key_nutrition] || [],
    [Db.evol_suffisance_revenu]: allData[Db.key_suffisance] || [],
    [Db.evol_montant_epargne]: allData[Db.key_epargne] || [],
    [Db.evol_investissment_envisage]: [],
    [Db.evol_investissement_effectue]: [],
    [Db.evol_niveau_comprehension_ecologique]: allData[Db.key_pratique] || [],
    [Db.evol_sensibilite_environnement]: allData[Db.key_pratique] || [],
    [Db.evol_pratique_norme_agro_eco]: allData[Db.key_pratique] || [],
    [Db.evol_pratique_elevage_eco]: allData[Db.key_pratique] || [],
    [Db.evol_pratique_respect_norme_environnement]: allData[Db.key_pratique] || [],
    [Db.evol_activite_impact_environnement_positif]: allData[Db.key_impact] || [],
  });

  const [selectedVal, setSelectedVal] = useState({});
  const [data, loadEvol, cls] = useFetch("get_evolution/");
  const [dataEvol, loadSetEvol, clsEvol] = useFetch("evolution");
  const [dataInvestissement, loadInvestissement, clsInvestissement] =
    useFetch("get_investissement");

  const setSelectValue = (name, value) => {
    setSelectedVal((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    (async function () {
      if (!!evolId && evolId !== null) {
        loadEvol({}, "get", "json", false, false, evolId).then(
          (resp) => {
            if (resp) {
              let { id, ...other } = resp;

              for (let item in other) {
                setValue(item, other[item], { shouldTouch: true });

                if (Object.keys(allOptions).includes(item)) {
                  setSelectValue(item, other[item]);
                }
              }
            }
          }
        );
      }

      await loadInvestissement().then((rep) => {
        if (rep) {
          setAllOptions((prev) => ({
            ...prev,
            [Db.evol_investissement_effectue]: rep,
            [Db.evol_investissment_envisage]: rep,
          }));
        }
      });
    })();

    return () => {
      cls.cancel();
    };
  }, []);

  const sendData = async (dataSend) => {
    console.log(
      "🚀 ~ file: index.jsx ~ line 18 ~ sendData ~ dataSend",
      dataSend
    );

    let _tmp = { ...aInitForm, ...dataSend, ...selectedVal };
    console.log("🚀 ~ file: index.jsx ~ line 18 ~ sendData ~ _tmp", _tmp);

    let formdata = await toFormdata(_tmp);
    let _param = !!evolId && evolId !== null ? `/${evolId}?_method=PUT`  : "";
    if (_param == '') {
      formdata.append(Db.evol_beneficiaire, benefId);
    }
    loadSetEvol(formdata, "post", "json", true, true, _param).then((rep) => {
      if (rep) {
        // setDataList(initList);
        // setTimeout(closeDrawer(), 500);
      }
    });
  };

  return (
    <div>
      <Box p={2} pb={0}>
        <Typography variant="h5" component="h1" fontWeight="bold">
          Ajout ou modification évolution bénéficiaire
        </Typography>
        <Typography variant="subtitle2" component="p">
          Ajouter ou modifier ci-dessous l'évolution d'un béneficiaire
        </Typography>
      </Box>

      <form>
        <Box
          p={2}
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat( auto-fill, minmax(280px, 1fr) )",
            gap: "15px",
            overflow: "hidden",
          }}
        >
          {fields.map((props, key) => (
            <FormData
              key={key}
              {...props}
              {...register(props.name)}
              control={control}
              options={allOptions[props.name]}
              selectValue={selectedVal[props.name]}
              selectFunction={setSelectValue}
            />
          ))}
        </Box>
        <Box p={2}>
          <LoadingButton
            onClick={handleSubmit(sendData)}
            color="primary"
            variant="contained"
          >
            Envoyer
          </LoadingButton>
        </Box>
      </form>
    </div>
  );
};

export default Add;
