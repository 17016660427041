import {
  DialogTitle,
  DialogContent,
  TableCell,
  TableRow,
  TableBody,
  Table,
} from "@mui/material";
import useFetch from "hooks/useFetch";
import { useEffect, useState } from "react";
import Chargement from "components/Chargement";
import { Db } from "helper/db";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import i18n from "helper/i18n";

const Details = ({ currentId, close }) => {
  const [data, load, cancel] = useFetch("get_evolution_details/" + currentId);
  const [details, setDetails] = useState({});

  useEffect(() => {
    load().then((rep) => {
      if (rep) {
        let _tmp = { ...rep };
        delete _tmp[Db.evol_id];
        delete _tmp[Db.evol_beneficiaire];
        setDetails(_tmp);
      }
    });

    return () => {
      cancel.cancel();
    };
  }, []);

  return (
    <>
      <DialogTitle>
        Details évolution{" "}
        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Chargement loading={data.loading} />
        <Table>
          <TableBody>
            {details &&
              Object.keys(details).map((item, keyRow) => (
                <TableRow
                  key={keyRow}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <b>{i18n[item]}</b>
                  </TableCell>
                  <TableCell align="right">
                    {Array.isArray(details[item])
                      ? details[item].join(", ")
                      : details[item]}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </DialogContent>
    </>
  );
};

export default Details;
