import { useState, useContext } from "react";
import { TextField, Collapse, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { LockOpen } from "@mui/icons-material";

import mainLogo from "assets/img/fma.png";

import "./style.scss";
import LoadingButton from "@mui/lab/LoadingButton";
import { Db } from "helper/db";
import useFetch from "hooks/useFetch";

import authService, { AuthContext } from "services/authService";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const { signin } = useContext(AuthContext);
  const [toSend, setToSend] = useState({
    [Db.user_login]: "",
    [Db.user_mdp]: "",
  });

  const [data, doSigning, cancel] = useFetch("login");
  const [dataGenre, loadGenre, clsGenre] = useFetch("get_genre");
  const [dataEtude, loadEtude, clsEtude] = useFetch("get_education");
  const [dataMatrimonial, loadMatrimonial, clsMatrimonial] =
    useFetch("get_matrimonial");
  const [dataRegion, loadRegion, clsRegion] = useFetch("get_region");
  const [dataDistrict, loadDistrict, clsDistrict] = useFetch("get_district");
  const [dataSuffisance, loadSuffisance, clsSuffisance] = useFetch("get_suffisance");
  const [dataStock, loadStock, clsStock] = useFetch("get_stock");
  const [dataNutrition, loadNutrition, clsNutrition] = useFetch("get_nutrition");
  const [dataEpargne, loadEpargne, clsEpargne] = useFetch("get_epargne");
  const [dataPratique, loadPratique, clsPratique] = useFetch("get_pratique");
  const [dataImpact, loadImpact, clsImpact] = useFetch("get_impact");
  const [dataAlim, loadAlim, clsAlim] = useFetch("get_stock_alim");

  const handleChange = (item) => {
    setToSend({ ...toSend, [item.target.name]: item.target.value });
  };

  const getAlldata = () =>
    new Promise(async (resolve) => {
      let _temp = {};

      await loadGenre().then((resp) => (_temp[Db.key_genre] = resp));
      await loadMatrimonial().then(
        (resp) => (_temp[Db.key_matrimonial] = resp)
      );
      await loadEtude().then((resp) => (_temp[Db.key_education] = resp));
      await loadRegion().then((resp) => (_temp[Db.key_region] = resp));
      await loadDistrict().then((resp) => (_temp[Db.key_district] = resp));

      await loadSuffisance().then((resp) => (_temp[Db.key_suffisance] = resp));
      await loadStock().then((resp) => (_temp[Db.key_stock] = resp));
      await loadNutrition().then((resp) => (_temp[Db.key_nutrition] = resp));
      await loadEpargne().then((resp) => (_temp[Db.key_epargne] = resp));
      await loadPratique().then((resp) => (_temp[Db.key_pratique] = resp));
      await loadImpact().then((resp) => (_temp[Db.key_impact] = resp));
      await loadAlim().then((resp) => (_temp[Db.key_alim] = resp));

      resolve(_temp);
    });

  const doLogin = async (evt) => {
    evt.preventDefault();
    await doSigning(toSend, "post")
      .then(async (resp) => {
        if (!!resp?.token) {
          toast.success("Connexion réussi! 👌");
          await authService.setAccessToken(resp.token);


          return resp;
        } else {
          toast("Error");
          throw new Error("Error");
        }
      })
      .then(async (resp) => {
        const allData = await toast.promise(
          getAlldata,
          {
            pending: "Récupération des données...",
            success: "Récupérer avec success 👌",
          },
          { delay: 600 }
        );

        await authService.setUserDetails({
          [Db.user_login]: resp[Db.user_login],
          [Db.user_mail]: resp[Db.user_mail],
          ...allData,
        });

          await signin(resp.token);

      }).then(()=> {
        navigate("/dashboard", { replace: true });

      });
  };

  return (
    <div className="login">
      <img src={mainLogo} alt="" />

      <div className="login-form">
        <form onSubmit={doLogin}>
          <TextField
            label="Identifiant"
            value={toSend[Db.user_login] || ""}
            variant="filled"
            size="small"
            fullWidth
            className="block-field"
            name={Db.user_login}
            onChange={handleChange}
            autoComplete={"false"}
            color="darksecondary"
          />
          <TextField
            label="Mot de passe"
            value={toSend[Db.user_mdp] || ""}
            type="password"
            variant="filled"
            size="small"
            fullWidth
            className="block-field"
            name={Db.user_mdp}
            onChange={handleChange}
            color="darksecondary"
          />
          <LoadingButton
            variant="contained"
            color="primary"
            loading={data.loading}
            disableElevation
            endIcon={<LockOpen />}
            type="submit"
            size="small"
          >
            Se connecter
          </LoadingButton>
        </form>

        <Collapse in={!!data.error} style={{ marginTop: 10 }}>
          <Alert severity="error">{data.error}</Alert>
        </Collapse>
      </div>
    </div>
  );
};

export default Login;
