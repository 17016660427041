import { TEXT, SELECT, FILE, TAG, ADDSELECT } from "components/FormFieldData";
import { Db } from "helper/db";
import authService from "services/authService";

const fields = [
  {
    formType: TEXT,
    label: "Login",
    name: Db.user_login,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Email",
    name: Db.user_mail,
    type: "mail",
  },
  {
    formType: TEXT,
    label: "Mot de passe",
    name: Db.user_mdp,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Confirmer mot de passe",
    name: Db.user_mdp_confirm,
    type: "text",
  },
];

export default fields;
