import { Suspense, useContext } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";

////////////Component
import Layout from "components/Layout";
import NotFound from "components/NotFound";

////////////Page
import LoginPage from "pages/Login/LoginPage";

////////////Services
import { AuthContext } from "services/authService";
import AuthProvider from "services/provider";

///////////CSS
import "assets/sass/app.scss";

//////////Pages ::TODO:: make dynamic import of pages
import Dashboard from "pages/Dashboard";
import Beneficiaire from "pages/Beneficiaire";
import Add from "pages/Beneficiaire/Add";
import Details from "pages/Beneficiaire/details";
import Evolution from "pages/Evolution";
import DetailsEvolution from "pages/Evolution/details";
import SetEvolution from "pages/Evolution/Add";
import Profil from "pages/Profil";
import User from "pages/User";
import SetUser from "pages/User/Add";
import Profession from "pages/Profession"
import AddProfession from "pages/Profession/Add"
import Talent from "pages/Talent";
import AddTalent from "pages/Talent/Add"

import "react-toastify/dist/ReactToastify.min.css";
import { Slide, toast } from "react-toastify";
import Appartenance from "pages/Appartenance";
import AddAppartenance from "pages/Appartenance/Add";
import Association from "pages/Association";
import AddAssociation from "pages/Association/Add";

toast.configure({
  position: toast.POSITION.BOTTOM_RIGHT,
  theme: "dark",
  transition: Slide,
});

const routes = [
  {
    url : "/dashboard",
    component : <Dashboard />
  },
  {
    url : "/user",
    component : <User />
  },
  {
    url : "/set_user",
    component : <SetUser />
  },
  {
    url: "/beneficiaire",
    component: <Beneficiaire />,
  },
  {
    url: "/ajout_beneficiaire",
    component: <Add />,
  },
  {
    url: "/details_beneficiaire",
    component: <Details />,
  },
  {
    url: "/evolution",
    component: <Evolution />,
  },
  {
    url: "/details_evolution",
    component: <DetailsEvolution />,
  },
  {
    url: "/set_evolution",
    component: <SetEvolution />,
  },
  {
    url: "/profil",
    component: <Profil />,
  },
  {
    url: "/profession",
    component: <Profession />,
  },
  {
    url: "/set_profession",
    component: <AddProfession />,
  },
  {
    url: "/talent",
    component: <Talent />,
  },
  {
    url: "/set_talent",
    component: <AddTalent />,
  },
  {
    url: "/appartenance",
    component: <Appartenance />,
  },
  {
    url: "/set_appartenance",
    component: <AddAppartenance />,
  },
  {
    url: "/association",
    component: <Association />,
  },
  {
    url: "/set_association",
    component: <AddAssociation />,
  },
]

const App = () => {
  return (
    <>
      <AuthProvider>
        <Suspense fallback={<>...</>}>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<LoginPage />} />
              {routes.map((item, key) => (
                 <Route
                 key={key}
                 path={item.url}
                 element={
                   <RequireAuth>
                     {item.component}
                   </RequireAuth>
                 }
               />
              ))}
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </AuthProvider>
    </>
  );
};

export default App;

function RequireAuth({ children }) {
  let auth = useContext(AuthContext);
  let location = useLocation();

  if (!auth.isAuth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" state={{ path: location.pathname }} replace />;
  }

  return children;
}
