import { useState, useEffect, useContext } from "react";
import { Outlet } from "react-router-dom";
import {
  useMediaQuery,
  Drawer,
  Box,
  Fade,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Select from "react-select";

import LeftMenu from "components/LeftMenu";
import Login from "pages/Login";
import authService, { AuthContext } from "services/authService";

const Layout = () => {
  const isLargeScreen = useMediaQuery("(min-width:940px)");
  const [menuToggle, setMenuToggle] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [region, setRegion] = useState("");
  const { isAuth } = useContext(AuthContext);

  const details = authService.getUserDetails();

  const handleToggleMenu = () => {
    setMenuToggle(!menuToggle);
  };

  const handleChangeRegion = (item) => {
    authService.setUserDetails({
      ...details,
      currentRegion: item,
    });
    setRegion(item);
    setShowMsg(true);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  useEffect(() => {
    if (menuToggle) {
      setMenuToggle(false);
    }
  }, [isLargeScreen]);

  useEffect(() => {
    setRegion(details?.currentRegion);
  }, []);

  return (
    <Box>
      <Box component="nav">
        <Drawer
          anchor="left"
          open={menuToggle}
          onClose={handleToggleMenu}
          variant={isLargeScreen ? "permanent" : "temporary"}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Box sx={{ width: "320px", height: "100vh", overflow: "hidden" }}>
            <Fade
              in={isAuth}
              unmountOnExit
              {...(isAuth ? { timeout: 2000 } : {})}
            >
              <div>
                <LeftMenu />
              </div>
            </Fade>
            <Fade
              in={!isAuth}
              unmountOnExit
              {...(!isAuth ? { timeout: 2500 } : {})}
            >
              <div>
                <Login />
              </div>
            </Fade>
          </Box>
        </Drawer>
      </Box>
      <Box component="main" sx={{ marginLeft: isLargeScreen ? "320px" : 0 }}>
        <Box sx={{ flexGrow: 1 }}>
          {isAuth && (
            <AppBar position="static" color="inherit" elevation={0}>
              <Toolbar variant="dense">
                <IconButton
                  size="small"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={handleToggleMenu}
                >
                  <MenuIcon />
                </IconButton>

                <Select
                  placeholder="Sélectionner un région"
                  options={details.regions || []}
                  onChange={handleChangeRegion}
                  value={region}
                />
                {showMsg && (
                  <Typography
                    component="span"
                    variant="body2"
                    sx={{ marginLeft: 2 }}
                  >
                    Rechargement de la page dans 2s...
                  </Typography>
                )}
              </Toolbar>
            </AppBar>
          )}
        </Box>
        <Outlet context={{ handleToggleMenu: handleToggleMenu }} />
      </Box>
    </Box>
  );
};

export default Layout;
