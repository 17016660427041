import { useContext } from "react";

import Box from "@mui/material/Box";
import { styled, ThemeProvider, createTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import ArrowRight from "@mui/icons-material/ArrowRight";
import Home from "@mui/icons-material/Home";
import People from "@mui/icons-material/People";
import AddCircle from "@mui/icons-material/AddCircle";
import ExitToApp from "@mui/icons-material/ExitToApp";

import { defaultTheme } from "assets/theme";
import { AuthContext } from "services/authService";
import { useNavigate, useLocation } from "react-router-dom";
import useQuery from "hooks/useQuery";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Work from "@mui/icons-material/Work";
import FolderShared from "@mui/icons-material/FolderShared";
import AutoAwesome from "@mui/icons-material/AutoAwesome";
import Groups from "@mui/icons-material/Groups";
import Workspaces from "@mui/icons-material/Workspaces";

const data = [
  { icon: <People />, label: "Utilisateur", url: "user" },
  {
    divider : true,
  },
  { icon: <FolderShared />, label: "Bénéficiaire", url: "beneficiaire" },
  {
    icon: <AddCircle />,
    label: "Ajout bénéficiaire",
    url: "ajout_beneficiaire",
  },
  {
    divider : true,
  },
  {
    icon: <Work />,
    label: "Profession",
    url: "profession",
  },
  {
    divider : true,
  },
  {
    icon: <AutoAwesome />,
    label: "Talent",
    url: "talent",
  },
  {
    divider : true,
  },
  {
    icon: <Workspaces />,
    label: "Appartenance",
    url: "appartenance",
  },
  {
    divider : true,
  },
  {
    icon: <Groups />,
    label: "Association",
    url: "association",
  },
  // { icon: <PermMedia />, label: "Storage", url: "stockage" },
];

const FireNav = styled(List)({
  "& .MuiListItemButton-root": {
    paddingLeft: 24,
    paddingRight: 24,
  },
  "& .MuiListItemIcon-root": {
    minWidth: 0,
    marginRight: 16,
  },
  "& .MuiSvgIcon-root": {
    fontSize: 20,
  },
});

const LeftMenu = () => {
  const { signout } = useContext(AuthContext);
  let queryId = useQuery().get("ref");
  const navigate = useNavigate();

  const Logout = () => {
    signout();
    navigate("/", { replace: true });
  };

  const goTo = async (url) => {
    if (url === "ajout_beneficiaire") {
      // await authService.updateUserDetails("current_user_id", null);
      if (queryId !== null) {
        navigate(url, { replace: true });
        window.location.reload(true);
      }
    }
    await navigate(url);
  };

  return (
    <ThemeProvider
      theme={createTheme(defaultTheme, {
        components: {
          MuiListItemButton: {
            defaultProps: {
              disableTouchRipple: true,
            },
          },
          MuiPaper: {
            styleOverrides: {
              root: {
                borderRadius: 0,
              },
            },
          },
        },
        palette: {
          mode: "dark",
          primary: { main: "#e6d03f" },
          background: { paper: "#6cad95" },
          text: {
            primary: "#fff",
          },
        },
      })}
    >
      <Paper elevation={0} sx={{ width: "320px", height: "100vh" }}>
        <FireNav component="nav" disablePadding>
          <Divider />
          <ListItemButton>
            <ListItemIcon
              sx={{ fontSize: 20, textShadow: "0px 1px 1px rgba(0,0,0,0.8)" }}
            >
              🌳
            </ListItemIcon>
            <ListItemText
              sx={{ my: 0 }}
              primary="Suivi-evaluation"
              primaryTypographyProps={{
                fontSize: 20,
                fontWeight: "medium",
                letterSpacing: 0,
              }}
            />
          </ListItemButton>
          <Divider />
          <ListItem component="div" disablePadding>
            <ListItemButton
              sx={{ height: 56 }}
              onClick={() => navigate("/dashboard")}
            >
              <ListItemIcon>
                <Home color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                primaryTypographyProps={{
                  color: "primary",
                  fontWeight: "medium",
                  variant: "body1",
                }}
              />
            </ListItemButton>
            <Tooltip title="Se déconnecter">
              <IconButton
                size="large"
                sx={{
                  "& svg": {
                    color: "rgba(255,255,255,0.8)",
                    transition: "0.2s",
                    transform: "translateX(0) rotate(0)",
                  },
                  "&:hover, &:focus": {
                    bgcolor: "unset",
                    "& svg:first-of-type": {
                      transform: "translateX(-4px)",
                    },
                    "& svg:last-of-type": {
                      right: 0,
                      opacity: 1,
                    },
                  },
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    height: "80%",
                    display: "block",
                    left: 0,
                    width: "1px",
                    bgcolor: "divider",
                  },
                }}
                onClick={() => Logout()}
              >
                <ExitToApp />
                <ArrowRight
                  sx={{ position: "absolute", right: 4, opacity: 0 }}
                />
              </IconButton>
            </Tooltip>
          </ListItem>
          <Divider />
          <Box
          /* sx={{
                bgcolor: open ? "rgba(71, 98, 130, 0.2)" : null,
                pb: open ? 2 : 0,
              }} */
          >
            <ListItemButton
              sx={{ py: 1, minHeight: 32, color: "rgba(255,255,255,.8)" }}
              onClick={() => goTo("/profil")}
            >
              <ListItemIcon sx={{ color: "inherit" }}>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Profil"}
                primaryTypographyProps={{
                  fontSize: 14,
                  fontWeight: "medium",
                }}
              />
            </ListItemButton>
            <ListItemButton alignItems="flex-start" sx={{ py: 2 }}>
              <ListItemText
                primary="Général"
                primaryTypographyProps={{
                  fontSize: 15,
                  fontWeight: "medium",
                  lineHeight: "20px",
                }}
                sx={{ my: 0 }}
              />
            </ListItemButton>

            {data.map((item, key) => (
              item.divider ? <Divider key={key}/> :
              <ListItemButton
                key={key}
                sx={{ py: 0, minHeight: 32, color: "rgba(255,255,255,.8)" }}
                onClick={() => goTo(item.url)}
              >
                <ListItemIcon sx={{ color: "inherit" }}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    fontSize: 14,
                    fontWeight: "medium",
                  }}
                />
              </ListItemButton>
            ))}
          </Box>
        </FireNav>
      </Paper>
    </ThemeProvider>
  );
};

export default LeftMenu;
