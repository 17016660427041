import { TEXT, SELECT, FILE, TAG, ADDSELECT } from "components/FormFieldData";
import { Db } from "helper/db";

const fields = [
  {
    formType: TEXT,
    label: "Nom",
    name: Db.profession_nom,
    type: "text",
  },
];

export default fields;
