export const Db = {
  user_id: "resp_identity",
  user_login: "login",
  user_mail: "email",
  user_mdp: "password",
  user_mdp_confirm: "password_confirmation",

  // Autres variables
  page_total: "page_total",
  pagination_page: "page",
  data_all: "data",
  annonce_details: "details",

  paginate_total: "total",
  paginate_data: "data",

  // Keys data beneficiaire
  key_education: "education",
  key_matrimonial: "matrimonial",
  key_genre: "genre",
  key_district: "district",
  key_region: "regions",

  // Keys evolution select
  key_suffisance: "suffisance",
  key_stock: "stock",
  key_nutrition: "nutrition",
  key_epargne: "epargne",
  key_pratique: "pratique",
  key_impact: "impact",
  key_alim: "stock_alim",

  logged_login: "logged_login",
  logged_nom: "logged_nom",
  logged_role: "logged_role",

  // Statistique
  min_age: "min_age",
  max_age: "max_age",
  total_jeune: "total_jeune",

  // Beneficiaire
  benef_id: "id",
  benef_responsable: "benef_responsable",
  benef_photo: "benef_photo",
  benef_nom: "benef_nom",
  benef_cin: "benef_cin",
  benef_annee_naiss: "benef_annee_naiss",
  benef_lieu_naiss : "benef_lieu_naiss",
  benef_genre: "benef_genre",
  benef_matrimonial: "benef_matrimonial",
  benef_nbr_pers_active: "benef_nbr_pers_active",
  benef_nbr_pers_charge: "benef_nbr_pers_charge",
  benef_email: "benef_email",
  benef_telephone: "benef_telephone",
  benef_facebook: "benef_facebook",
  benef_adress: "benef_adress",
  benef_fokotany: "benef_fokotany",
  benef_commune: "benef_commune",
  benef_district: "benef_district",
  benef_region : "benef_region",
  benef_resp_assoc_groupement: "benef_resp_assoc_groupement",
  benef_education: "benef_education",
  benef_annee_debut_activite_prof: "benef_annee_debut_activite_prof",
  benef_nom_assoc: "benef_nom_assoc",
  benef_resp_assoc: "benef_resp_assoc",
  benef_autre_responsabilite: "benef_autre_responsabilite",
  benef_autre_appartenance: "benef_autre_appartenance",
  benef_profession: "benef_profession",
  benef_talent: "benef_talent",

  // Evolution
  evol_id : "id",
  evol_date_evolution : "evol_date_evolution",
  evol_beneficiaire : "evol_beneficiaire",
  evol_surface_dispo : "evol_surface_dispo",
  evol_surface_utilise : "evol_surface_utilise",
  evol_total_prod_vegetal : "evol_total_prod_vegetal",
  evol_total_prod_animal : "evol_total_prod_animal",
  evol_suffisance_alimentaire : "evol_suffisance_alimentaire",
  evol_suffisance_proteine : "evol_suffisance_proteine",
  evol_stock_periode_soudure : "evol_stock_periode_soudure",
  evol_stock_alimentaire : "evol_stock_alimentaire",
  evol_niveau_nutrition : "evol_niveau_nutrition",
  evol_total_vente_prod_vegetal : "evol_total_vente_prod_vegetal",
  evol_total_vente_prod_animal : "evol_total_vente_prod_animal",
  evol_autre_revenu : "evol_autre_revenu",
  evol_suffisance_revenu : "evol_suffisance_revenu",
  evol_montant_epargne : "evol_montant_epargne",
  evol_depense_couverte_revenu : "evol_depense_couverte_revenu",
  evol_investissment_envisage : "evol_investissment_envisage",
  evol_investissement_effectue : "evol_investissement_effectue",
  evol_niveau_comprehension_ecologique : "evol_niveau_comprehension_ecologique",
  evol_sensibilite_environnement : "evol_sensibilite_environnement",
  evol_pratique_norme_agro_eco : "evol_pratique_norme_agro_eco",
  evol_pratique_elevage_eco : "evol_pratique_elevage_eco",
  evol_pratique_respect_norme_environnement : "evol_pratique_respect_norme_environnement",
  evol_activite_impact_environnement_positif : "evol_activite_impact_environnement_positif",
  evol_autre_infos : "evol_autre_infos",

  // District
  district_id : "id",

  // Select
  select_label : "label",
  select_value : "value",

  profession_label: "label",
  profession_value: "value",
  profession_nom: "nom",

  talent_label: "label",
  talent_value: "value",
  talent_nom: "nom",

  appartenance_label: "label",
  appartenance_value: "value",
  appartenance_nom: "nom",

  association_label: "label",
  association_value: "value",
  association_nom: "nom",
  
};
