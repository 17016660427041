import { Doughnut, Pie, PolarArea, Radar, Bar } from "react-chartjs-2";
import { Paper, Box, Typography } from "@mui/material";

export const sTypeDougnhut = "doughnut";
export const sTypePie = "pie";
export const sTypePolar = "polar";
export const sTypeRadar = "radar";
export const sTypeBar = "bar";
export const optionsBar = {
  indexAxis: "y",
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      position: "right",
    },
    title: {
      display: true,
      text: "Chart.js Horizontal Bar Chart",
    },
  },
};

const SetGraph = ({ type, data, id }) => {
  switch (type) {
    case sTypeDougnhut:
      return <Doughnut data={data} />;
    case sTypePie:
      return <Pie data={data} />;
    case sTypePolar:
      return <PolarArea data={data} />;
    case sTypeRadar:
      return <Radar data={data} />;
    case sTypeBar:
      return <Bar data={data} options={optionsBar} />;

    default:
      return "";
  }
};

const BuildGraph = ({ type, label, data }) => {
  return (
    <Box sx={{ minHeight: "240px" }}>
      <Typography variant="body1" component="p" fontWeight="bold">
        {label}
      </Typography>

      {!!data?.datasets && (
        <Box sx={{ width: "100%" }}>
          <SetGraph type={type} data={data} />
        </Box>
      )}
    </Box>
  );
};

export default BuildGraph;
