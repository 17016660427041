// import loadable from "@loadable/component";
import { useCallback, useState, useEffect } from "react";

// export const AsyncPage = loadable(
//   (props) => import(`src/pages/${props.page}`),
//   {
//     cacheKey: (props) => props.page,
//     fallback: (
//       <div className="main_laoder">
//         <h4> Chargement...</h4>
//       </div>
//     ),
//   }
// );

export function getKey(object, value) {
  if (Array.isArray(value)) {
    let _tmp = [];
    value.map((item) =>
      _tmp.push(Object.keys(object).find((key) => object[key] === item))
    );
    return _tmp;
  } else {
    return Object.keys(object).find((key) => object[key] === value);
  }
}

export function useLocalStorage(key, defaultValue) {
  return useStorage(key, defaultValue, window.localStorage);
}

export function useSessionStorage(key, defaultValue) {
  return useStorage(key, defaultValue, window.sessionStorage);
}

function useStorage(key, defaultValue, storageObject) {
  const [value, setValue] = useState(() => {
    const jsonValue = storageObject.getItem(key);
    if (jsonValue != null) return JSON.parse(jsonValue);

    if (typeof initialValue === "function") {
      return defaultValue();
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (value === undefined) return storageObject.removeItem(key);
    storageObject.setItem(key, JSON.stringify(value));
  }, [key, value, storageObject]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return [value, setValue, remove];
}

export function toFormdata(data) {
  let formdata = new FormData();

  for (let item in data) {
    if (Array.isArray(data[item])) {
      if (data[item].length > 0) {
        data[item].forEach((element) => {
          if (typeof element !== 'object') { // For depense couverte
            formdata.append(`${item}[]`, element)
          } else {
            if ('__isNew__' in element)
              formdata.append(`${item}[new][]`, element['value'])
            else
              formdata.append(`${item}[data][]`, element['value'])
            }
          }
        );
      } else {
        formdata.append(`${item}`, []);
      }
    } else if(typeof data[item] === 'object' && data[item] != null) {
      if ('__isNew__' in data[item]){
        formdata.append(`${item}[new]`, data[item]['value'])
      } else {
        formdata.append(item, data?.[item]?.['value'] || []);
      }
    } else {
      formdata.append(item, data[item] || '');
    }
  }
  console.warn('formdata');

  // Display the key/value pairs
  for (var pair of formdata.entries()) {
    console.log(pair[0]+ ', ' + pair[1]);
  }
  return formdata;
}
