import Button from "@mui/material/Button";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

import "./style.scss";

const NotFound = () => {
  let navigate = useNavigate();

  return (
    <div className="not-found">
      <div className="not-found-cointainer">
        <h3 className="not-found-title">
          <span> Oups</span> <br /> Page non trouvée
        </h3>
        <Button
          variant="text"
          color="primary"
          startIcon={<ArrowBack />}
          onClick={() => navigate(-1)}
        >
          Retour
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
