import { useEffect, useState } from "react";
import { Box, Typography, Button, Dialog, Drawer } from "@mui/material";
import { useNavigate } from "react-router-dom";

import TableData from "components/TableData";
import { Db } from "helper/db";
import useFetch from "hooks/useFetch";

import Delete from "./delete";

const Profession = () => {
  const [data, load, cancel] = useFetch("get_profession");
  const [professions, setProfessions] = useState([])
  const [dialOpen, setDialOpen] = useState(false);
  const [dialDelete, setDialDelete] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const navigate = useNavigate();

  const [dataDelete, loadDelete, cancelDelete] = useFetch("profession/" + currentId);

  const add = async () => {
    await navigate("/set_profession");
  };
  
  const mainLoad = () =>{
    load().then(resp =>{
      if (resp && resp.length > 0) {
        let profes = resp.map(item => ({...item, id : item.value}))
        setProfessions(profes)
      } 
    });
  }

  const handleDialogDelete = () => {
    setDialDelete(!dialDelete);
  };

  const edit = async (data) => {
    await navigate(`/set_profession?ref=${data.id}`);
  };

  const deleteUser = async (data) => {
    await setCurrentId(data.id);
    await handleDialogDelete();
  };

  const confirmDelete = async () => {
    loadDelete({}, "delete", "json", true).then((rep) => {
      if (rep) {
        mainLoad();
        handleDialogDelete();
      }
    });
  };

  const columns = [
    { field: "id", hide: true },
    { field: Db.profession_label, headerName: "Profession", width: 250 },
  ];

  const actColums = [
    {
      field: "action",
      headerName: "",
      width: 200,
      renderCell: (params) => (
        <>
          <Button color="primary" size="small" onClick={() => edit(params)}>
            Modifier
          </Button>
          <Button
            color="primary"
            size="small"
            onClick={() => deleteUser(params)}
          >
            Supprimer
          </Button>
        </>
      ),
    },
  ];


  useEffect(() => {
    
    mainLoad();
    return () => {
      cancel.cancel();
      cancelDelete.cancel();
    };
  }, []);

  return (
    <div>
      <Box p={2}>
        <Typography variant="h5" component="h1" fontWeight="bold">
          Professions
        </Typography>
      </Box>

      <Box p={2}>
        <Typography variant="h5" component="h1" fontWeight="bold">
          <Button color="primary" size="small" onClick={() => add()}>
            Ajouter
          </Button>
        </Typography>
      </Box>

      <Dialog fullWidth={true} maxWidth={"sm"} open={dialDelete}>
        {dialDelete && (
          <Delete
            confirmDelete={confirmDelete}
            closeDialog={handleDialogDelete}
            loading={dataDelete.loading}
          />
        )}
      </Dialog>

      <Box p={2}>
        <TableData
          rows={professions || []}
          columns={[...columns, ...actColums]}
          height="82vh"
          width="100%"
          loading={data.loading}
        />
      </Box>
    </div>
  );
};

export default Profession;
