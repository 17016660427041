import { TEXT, SELECT, FILE, TAG, ADDSELECT } from "components/FormFieldData";
import { Db } from "helper/db";

const fields = [
  {
    formType: FILE,
    label: "Photo",
    name: Db.benef_photo,
    type: "file",
    InputLabelProps: { shrink: true },
  },
  {
    formType: TEXT,
    label: "Nom",
    name: Db.benef_nom,
    type: "text",
  },
  {
    formType: TEXT,
    label: "CIN",
    name: Db.benef_cin,
    type: "number",
  },
  {
    formType: TEXT,
    label: "Date de naissance",
    name: Db.benef_annee_naiss,
    type: "date",
    InputLabelProps: { shrink: true },
  },
  {
    formType: TEXT,
    label: "Lieu de naissance",
    name: Db.benef_lieu_naiss,
    type: "text",
  },
  {
    formType: SELECT,
    label: "Genre",
    name: Db.benef_genre,
    options: [],
  },
  {
    formType: SELECT,
    label: "Matrimonial",
    name: Db.benef_matrimonial,
    options: [],
  },

  {
    formType: SELECT,
    label: "Région",
    name: Db.benef_region,
    options: [],
  },
  {
    formType: SELECT,
    label: "District",
    name: Db.benef_district,
    options: [],
  },
  {
    formType: TEXT,
    label: "Commune",
    name: Db.benef_commune,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Fokotany",
    name: Db.benef_fokotany,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Adresse",
    name: Db.benef_adress,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Telephone",
    name: Db.benef_telephone,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Email",
    name: Db.benef_email,
    type: "mail",
  },
  {
    formType: TEXT,
    label: "Facebook",
    name: Db.benef_facebook,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Responsabilité dans association/groupement",
    name: Db.benef_resp_assoc_groupement,
    type: "text",
  },
  {
    formType: SELECT,
    label: "Niveau éducation",
    name: Db.benef_education,
    options: [],
  },
  {
    formType: TEXT,
    label: "Année debut d'activité",
    name: Db.benef_annee_debut_activite_prof,
    type: "number",
  },
  {
    formType: ADDSELECT,
    isMulti: false, 
    label: "Nom association",
    name: Db.benef_nom_assoc,
    options: [],
  },
  {
    formType: TEXT,
    label: "Responsabilité dans association",
    name: Db.benef_resp_assoc,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Autre responsabilité",
    name: Db.benef_autre_responsabilite,
    type: "text",
  },
  {
    formType: TEXT,
    label: "Nombre de personne active dans famille",
    name: Db.benef_nbr_pers_active,
    type: "number",
  },
  {
    formType: TEXT,
    label: "Nombre de personne à charge",
    name: Db.benef_nbr_pers_charge,
    type: "number",
  },
  {
    formType: ADDSELECT,
    label: "Autre appartenance",
    name: Db.benef_autre_appartenance,
    options: [],
  },
  {
    formType: ADDSELECT,
    label: "Profession",
    name: Db.benef_profession,
    options: [],
  },
  {
    formType: ADDSELECT,
    label: "Talent",
    name: Db.benef_talent,
    options: [],
  },
];

export default fields;
